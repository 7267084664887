export default class DomainsShow {
    
    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      var self = this;

      self.datatable = $('#campaign_list_table').DataTable({
            "paging": true,
            "lengthChange": false,
            searching: true,
            language: {
                search: "Rechercher :",
                lengthMenu: "Afficher _MENU_ éléments",
                info: "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
                infoEmpty: "Aucun élément trouvé",
                infoFiltered: "(filtré sur _MAX_ éléments)",
                zeroRecords: "Aucun élément trouvé",
                paginate: {
                    first: "Premier",
                    previous: "Précédent",
                    next: "Suivant",
                    last: "Dernier"
                }
            },
            columnDefs: [
               { orderable: true, targets: 0, type: "num" },
               { orderable: true, targets: 1 },
               { orderable: false, targets: 2 },
               { orderable: false, targets: 3 },
               { orderable: false, targets: 4 },
               { orderable: false, targets: 5 },
               { orderable: true, targets: 6 },
               { orderable: false, targets: 7 },
            ]
        })

        $(".campaign-switch").on("change", function() {
          var url = $(this).data("url");
          $.ajax({
            url: url,
            method: "PUT",
            // success: function(data) {
            //   application.successNotification("Statut de la campagne modifié avec succès");
            // }
          });
        });    

        $(".campaign-row").on("click", function() {
          console.log($(this).data("href"));
          window.location = $(this).data("href");
        });

        $(".display-geolocation-item").on("click", function(e) {
          e.stopPropagation();
          var target = $(this).data("target");
          $(target).modal("show");
        });


        var data = [{
          id: "top-3⏺",
          text: '<span class="text-info pr-1">●</span> Top 3',
          html: '<span class="text-info pr-1">●</span> Top 3<div>',
          title: 'Top 3'
      }, {
          id: "top-10⏺",
          text: '<span class="text-success pr-1">●</span> Top 10',
          html: '<span class="text-success pr-1">●</span> Top 10<div>',
          title: 'Top 10'
      }, {
          id: "top-50⏺",
          text: '<span class="text-warning pr-1">●</span> Top 50',
          html: '<span class="text-warning pr-1">●</span> Top 50<div>',
          title: 'Top 50'
      }, {
          id: "top-100⏺",
          text: '<span class="text-danger pr-1">●</span> Top >50',
          html: '<span class="text-danger pr-1">●</span> Top >50<div>',
          title: 'Top >50'
      }];
      
      $("#top-filter").select2({
          placeholder: "Filtrer par statut",
          allowClear: true,
          multiple: true,
          closeOnSelect: false,
          data: data,
          templateResult: function (data) {
              if (!data.id) {
                  return data.text; // Display placeholder text
              }
              var $result = $('<span>' + data.html + '</span>');
              return $result;
          },
          templateSelection: function (data) {
              return $('<span>' + data.text + '</span>');
          }
      });

      $("#top-filter").on("change", function(e) {
        var values = $(this).val();
        console.log(values);
        self.datatable.columns(0).search(values.join("|"), true, false).draw();
      });
       
      $('#campaign_list_table_filter').appendTo('#search-wrapper');
      $('#campaign_list_table_filter label').css('margin', '0px');

      document.addEventListener('turbolinks:before-cache', function() {
        $("#search-wrapper").html("");
        if(self.datatable!=null) {
          self.datatable.destroy();
          self.datatable = null;
        }

        let select2Instance = $('#top-filter').data('select2');
        if(select2Instance) {
          select2Instance.destroy();
        }
      });
    }
}