export default class DomainsForm {
    
    constructor() {
        this.bindEvents();

        this.geolocation_results = {};
    }

    rebind() {
        this.bindEvents();
    }


    bindEvents() {
         var self = this;

        $("#domain_week_mobile_percent").off("input").on("input", function() {
            $("#week_mobile_percent_value").html(""+$(this).val());
        });

        $("#domain_weekend_mobile_percent").off("input").on("input", function() {
            $("#weekend_mobile_percent_value").html(""+$(this).val());
        });

        $("#domain_bounce_rate").off("input").on("input", function() {
            $("#bounce_rate_percent_value").html(""+$(this).val());
        });

        $(".distance_range").off("input").on("input", function() {
            $("#distance_value").html(""+$(this).val());
        });

        $(".btn-destroy-geolocation").on("click", function() {
            //$("#domain_form").submit();  
            var form = document.querySelector('#domain_form');
            Rails.fire(form, 'submit');
        });


        $("#geolocation_search_city").select2({
          ajax: {
            delay: 250,
            url: '/domains/search-city',
            data: function (params) {
              var query = {
                search: params.term,
              }

              // Query parameters will be ?search=[term]&type=public
              return query;
            },
            processResults: function (data) {
                self.geolocation_results = {};

                var select2Results = []

                $.each(data.results, function(idx, result) {
                    console.log(result);

                    self.geolocation_results[result.place_id] = result;

                    select2Results.push({ text: result.display_name, id: result.place_id });
                });
              // Transforms the top-level key of the response object from 'items' to 'results'
              return {
                results: select2Results
              };
            }
          }
        }).on("select2:select", function(e) {
            //console.log("Element selected ->");
            var result = self.geolocation_results[$(this).val()];


            $("#geolocation_latitude").val(result.lat);
            $("#geolocation_longitude").val(result.lon);
            if(result.addresstype=="postcode") {
              $("#geolocation_name").val(result.display_name.split(",")[1]);
            }
            else {
              $("#geolocation_name").val(result.name.length>0 ? result.name : result.display_name);
            }
            

        });
    }
}