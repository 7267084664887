import CampaignsGeolocationForm from './CampaignsGeolocationForm';

export default class CampaignsForm {

  constructor() {
      this.geolocation_form = new CampaignsGeolocationForm();
      this.bindEvents();
  }

  rebind() {
    this.bindEvents();
  }


  bindEvents() {

    if (document.readyState !== 'loading') {
      this.onPageLoad();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        this.onPageLoad();
      });
    };

    // $('.nav-link.new-campaign-nav-button').on('click', (event) => {
    //   if (this.checkFormValidity()){
    //     this.updateStepNumber(event.currentTarget);
    //   }else{
    //     event.stopPropagation();
    //   }
    // });

    $(".next-step-button").on("click", (e) => {
      $("#loaderModal").modal('show');
    });
    
    // $(".previous-step-button").on("click", (e) => {
    //   e.preventDefault();
    //   this.handleStepNavigation("previous");
    // });

    $("#switch").on('change', function() {
      if ($(this).is(':checked')){
        $('#specific_url_input_group').removeClass('d-none');
        $('#specific_url_field').prop('required', true);
      }else{
        $('#specific_url_input_group').addClass('d-none');
        $('#specific_url_field').prop('required', false);
      }
    });

    $('#submit_button').on('click', (event) => {
      if (!$('#switch').is(':checked')){
        $('#specific_url_field').val('');
      }
      if (this.checkFormValidity()){
        $('#new_campaign')[0].submit();
      }
    });

    $('.radio-card').on('click', function() {
      $('.radio-card').removeClass('active').addClass('not-active');
      
      $(this).addClass('active').removeClass('not-active');
    });

    $('#random-navigation-card').on('click', function() {
      $('#navigation_type').val('navigation_type_random');
      $('.random_navigation_field').each(function() {
        $(this).prop('required', true);
      });
      $('.recorded_navigation_field').first().prop('required', false);
    });
  
    $('#recorded-navigation-card').on('click', function() {
      $('#navigation_type').val('navigation_type_recorded');
      $('.random_navigation_field').each(function() {
        $(this).prop('required', false);
      });
      $('.recorded_navigation_field').first().prop('required', true);
    });

    $("#add-concurrent").on("click", (e) => {
      var templateRows = $('.template-row');
      if (templateRows.length < 10) {
        $("#concurrents-fields-container").append(this.addConcurrentRow());
        if ($('.template-row').length === 2) {
          $('.template-row').find('.delete-row-button').prop('disabled', false);
        }
      }
      
    });

    $(document).on('click', '.delete-row-button', function() {
      $(this).closest(".template-row").remove();

      var templateRows = $('.template-row');
      if (templateRows.length === 1) {
        templateRows.find('.delete-row-button').prop('disabled', true);
      }
    });

    $("#concurrent-switch").on('change', function() {
      if ($(this).is(':checked')){
        $('#concurrent_form').removeClass('d-none');
        var navigationType = "visit_concurrent_domains_specified";

        $('.concurrent_form_field').each(function() {
          $(this).prop('required', true);
        });
      }else{
        $('#concurrent_form').addClass('d-none');
        var navigationType = "visit_concurrent_domains_none";

        $('.concurrent_form_field').each(function() {
          $(this).prop('required', false);
        });
      }

      $("#visit-concurrent-domains-input").val(navigationType);
    });

    $("#radio_file").prop('checked', true);
    $("#radio_text").prop('checked', false);

    $("#radio_text").on('change', function() {
      $(".keywords-field").show();
      $(".keywords-field").find('input').prop('required', true);
    });

    $("#radio_file").on('change', function() {
      $(".keywords-field").hide();
      $(".keywords-field").find('input').prop('required', false);
    });

    $("#campaigns-datatable").DataTable({
      "paginate": true,
      "language": {
        "lengthMenu": "Afficher _MENU_ éléments",
        "zeroRecords": "Aucun résultat trouvé",
        "info": "Page _PAGE_ sur _PAGES_",
        "infoEmpty": "Aucun résultat trouvé",
        "infoFiltered": "(filtré sur _MAX_ éléments)",
        "search": "Rechercher :",
        "paginate": {
          "next": "Suivant",
          "previous": "Précédent"
        }
      },
      "info": false,
      "searching": false,
      "order": [[ 3, "desc" ]]
    });

    $(".campaign-switch").on("change", function() {
      var url = $(this).data("url");
      $.ajax({
        url: url,
        method: "PUT",
        // success: function(data) {
        //   application.successNotification("Statut de la campagne modifié avec succès");
        // }
      });
    });

    $(".frequency-field").on("blur", function() {
      if ($(this).val() === "" || $(this).val() < 1) {
        $(this).val(1);
      }

      var url = $(this).data("url");
      var frequency = $(this).val();

      $.ajax({
        url: url,
        method: "PUT",
        data: { frequency: frequency },
        // success: function(data) {
        //   application.successNotification("Fréquence de la campagne modifiée avec succès");
        // }
      });

    });

  }

  onPageLoad() {

    var self = this;

    $(".custom-select").select2({
      minimumResultsForSearch: -1,
      templateResult: this.formatState,
      templateSelection: this.formatState
    });

    $("#search_engine_id").on("change", function(e) {
      self.checkSearchEngine()
    });

    if ($('#switch').is(':checked')){
      $('#specific_url_input_group').removeClass('d-none');
    }

    if ($('#concurrent-switch').is(':checked')){
      $('#concurrent_form').removeClass('d-none');
    }

    self.checkSearchEngine();
   

    // $("#search_engine_id option[value='']").remove();

  };

  checkSearchEngine() {
    if($("#search_engine_id").val()=="3") {
      $("#campaign_business_name").prop("required", true);
      $(".gmb-part").removeClass("d-none");
      $(".custom-url-part").addClass("d-none");
    }
    else {
      $(".gmb-part").addClass("d-none");
      $(".custom-url-part").removeClass("d-none");
      $("#campaign_business_name").prop("required", false);
    }

    if ($("#search_engine_id").val() == "1" || $("#search_engine_id").val() == "3") {
      $(".bcsv").addClass("d-none");
      $(".gcsv").removeClass("d-none");
      $("#bing_header").value = "0";
    } else {
      $(".gcsv").addClass("d-none");
      $(".bcsv").removeClass("d-none");
      $("#bing_header").value = "1";
    }
  }

  checkFormValidity() {
    var form = document.getElementById('new_campaign');
      if (form.checkValidity()){
        return true
      }else{
        setTimeout(function() {
          form.reportValidity();
        }, 10);
        return false
      }

  }

  getSelectedTabIndex() {
    // Get the index of the currently selected tab
    return $('.nav-justified .nav-item.active').index();
  }

  
  addConcurrentRow() {
    var templateRows = $('.template-row');
    var usedCounts = [];
  
    // Collect used concurrent counts
    templateRows.each(function() {
      var count = parseInt($(this).data('concurrent-count'));
      usedCounts.push(count);
    });
  
    // Find the lowest unused concurrent count
    var newConcurrentCount = 1;
    while (usedCounts.includes(newConcurrentCount)) {
      newConcurrentCount++;
    }
  
    var lastTemplateRow = $('.template-row:last');
    var newTemplateRow = lastTemplateRow.clone();
  
    // Set the new concurrent count
    newTemplateRow.data('concurrent-count', newConcurrentCount);
  
    // Update data attributes in the cloned row
    newTemplateRow.find('[data-name]').each(function() {
      var attributeName = $(this).data('name');
      attributeName = attributeName.replace('__concurrent_count__', newConcurrentCount);
      $(this).attr('name', attributeName);
    });
  
    // Clear input values
    newTemplateRow.find('input[type="text"]').val('');
  
    return newTemplateRow;
  }
  


  formatState(opt) {
    if (!opt.id) {
      return opt.text.toUpperCase();
    }
  
    var optimage = $(opt.element).data('image');
    if (!optimage) {
      return opt.text.toUpperCase();
    } else {
      var $opt = $(
        '<span><img src="' + optimage + '" height="25px" style="margin-right: 10px;max-width:25px;max-height:25px"  /> ' + opt.text + '</span>'
      );
      return $opt;
    }
  }


  updateStepNumber(tab) {
    // Remove 'active' class from all spans
    $('.nav-link.new-campaign-nav-button .step-number').removeClass('active');

    // Add 'active' class to the clicked tab's step number and previous step numbers
    $(tab).closest('.nav-item').prevAll().addBack().find('.step-number').addClass('active');
  }

  // handleStepNavigation(direction) {
  //   var $currentNavLink = $('.nav-link.new-campaign-nav-button.active');
  
  //   var $newNavLink;
  //   if (direction === "next") {
  //     $newNavLink = $currentNavLink.parent().next().find('.nav-link.new-campaign-nav-button');
  //   } else if (direction === "previous") {
  //     $newNavLink = $currentNavLink.parent().prev().find('.nav-link.new-campaign-nav-button');
  //   }
  
  //   if ($newNavLink.length > 0) {
  //     $newNavLink[0].click();
  //   }
  // }
  

  onDataChanged(data) {
  }

  onDestroy() {
  }
}