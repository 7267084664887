import ConfigRoutes from "../../config/Routes";
import CampaignsShow from "../controllers/CampaignsShow";
import CampaignsForm from "../controllers/CampaignsForm";
import DomainsShow from "../controllers/DomainsShow";
import DomainsForm from "../controllers/DomainsForm";
import DomainsSettings from "../controllers/DomainsSettings";

export default class Routes extends ConfigRoutes{

    static routes = {
        "CampaignsShow": CampaignsShow,
        "CampaignsNew": CampaignsForm,
        "CampaignsEdit": CampaignsForm,
        "CampaignsEditOne": CampaignsForm,
        "DomainsShow": DomainsShow,
        "DomainsNew": DomainsForm,
        "DomainsEdit": DomainsForm,
        "DomainsSettings": DomainsSettings
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}