import ApexCharts from 'apexcharts';
import CampaignsGeolocationForm from './CampaignsGeolocationForm';

export default class CampaignsShow {
  constructor() {
    this.geolocation_form = new CampaignsGeolocationForm();
    this.dataTable = null;
    this.map = null;
    this.defaultPosition = $("#map").data("default-position");
    this.bindEvents();
  }

  bindEvents() {
    this.bindRadioButtonEvents();
    this.bindFrequencySubmit();
    this.bindPageLoadEvent();
    this.bindActivityDataTable();
    this.initializeMap();
    this.scrollToTableEnd();
    this.loadGridData();
  }

  bindRadioButtonEvents() {
    $('.stats-range-radio').on('click', () => {
      $('#time-range-form')[0].submit();
    });
  }

  bindFrequencySubmit() {
    $("#frequency_submit").on("click", () => this.updateFrequency());
  }

  bindPageLoadEvent() {
    if (document.readyState !== 'loading') {
      this.onPageLoad();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        this.onPageLoad();
      });
    }
  }

  scrollToTableEnd() {
    $("#position-variation-table").scrollLeft($("#position-variation-table")[0].scrollWidth);
  }

  initializeMap() {
    this.map = L.map('map').setView(this.defaultPosition, 16);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.addMarker(this.defaultPosition, '/leaflet/marker-icon-2x-red.png', this.map);
  }

  addMarker(position, iconUrl, map) {
    const defaultIcon = L.icon({
      iconUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
      shadowSize: [41, 41]
    });

    L.marker(position, { icon: defaultIcon }).addTo(map);
  }

  loadGridData() {
    this.loadGrid((gridData) => {
      gridData.forEach((item) => {
        item.forEach((gridItem) => {
          const bounds = [gridItem.bounds[0], gridItem.bounds[1]];
          const color = gridItem.being_processed ? "#FFFF00" : gridItem.color;
          this.createRectangle(bounds, color, gridItem, this.map);
          this.addGridItemMarker(gridItem);
        });
      });
    });
  }

  addGridItemMarker(gridItem) {
    if (gridItem.average_position === null || gridItem.average_position === "N/A") return;
    
    console.log(gridItem);

    const textIcon = L.divIcon({
      className: 'average-text',
      html: gridItem.average_position,
      iconSize: [20, 20],
    });

    L.marker(gridItem.center, { icon: textIcon }).addTo(this.map);
  }

  loadGrid(callback) {
    $.ajax({
      url: $("#map").data("grid-url"),
      data: $("#map").data("grid-params"),
      success: callback,
    });
  }

  createRectangle(bounds, color, gridItem, map) {
    const fillColor = gridItem.being_processed ? gridItem.color : color;
    L.rectangle(bounds, {
      color,
      fillColor,
      weight: gridItem.being_processed ? 2 : 0.5,
      fillOpacity: 0.3
    }).addTo(map);
  }

  onPageLoad() {
    this.loadRankChart();
  }

  loadRankChart() {
    const chartElement = document.querySelector("#rank_chart");
    if (!chartElement) return;

    chartElement.innerHTML = "";
    const dates = Object.keys(executionsStats);
    const values = Object.values(rankChartStats);
    const executions = Object.values(executionsStats);
    const maxValue = Math.max(...values);

    const options = this.getChartOptions(dates, values, executions, maxValue);

    const chart = new ApexCharts(chartElement, options);
    chart.render();
  }

  getChartOptions(dates, values, executions, maxValue) {
    return {
      series: [
        { name: 'Position', type: "line", data: values },
        { name: 'Sessions', type: "column", data: executions }
      ],
      chart: {
        height: 350,
        type: 'line',
        toolbar: { show: false },
        zoom: { enabled: false }
      },
      stroke: { width: 5 },
      xaxis: {
        type: 'datetime',
        forceNiceScale: true,
        min: new Date(dates[0]).getTime(),
        tickAmount: dates.length > 10 ? 10 : dates.length - 1,
        labels: { format: 'dd/MM' }
      },
      labels: dates,
      colors: ['#5993fd', '#80e0e5'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#f06b78'],
          opacityFrom: 1,
          opacityTo: 1,
          stops: [10, 90]
        }
      },
      yaxis: [
        {
          seriesName: "Position",
          min: 1,
          max: maxValue + 1,
          reversed: true,
          crosshairs: { show: false },
          labels: { formatter: (val) => (val != null ? val.toFixed(0) : null) }
        },
        {
          seriesName: "Sessions",
          opposite: true,
          crosshairs: { show: false },
          labels: { formatter: (val) => (val != null ? val.toFixed(0) : null) }
        }
      ],
      markers: { size: 5, colors: ["#5993fd"] },
    };
  }

  updateFrequency() {
    const url = $("#frequency_submit").data("url");
    const frequency = $("#campaign_frequency").val();

    $.ajax({
      url,
      type: 'PUT',
      data: { frequency },
      success: () => {
        $("#frequency_value").html(frequency);
        $("#edit-frequency").modal('hide');
        application.successNotification("Fréquence modifiée avec succès");
      }
    });
  }

  bindActivityDataTable() {
    this.dataTable = $('#activity-datatable').DataTable({
      "order": [[1, "desc"]],
      "paging": true,
      "language": {
        "search": "Rechercher :",
        "lengthMenu": "Afficher _MENU_ éléments",
        "info": "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
        "infoEmpty": "Aucun élément trouvé",
        "infoFiltered": "(filtré sur _MAX_ éléments)",
        "zeroRecords": "Aucun élément trouvé",
        "paginate": {
          "first": "Premier",
          "previous": "Précédent",
          "next": "Suivant",
          "last": "Dernier"
        }
      },
      "info": false,
      "searching": true,
    });
  }

  onDestroy() {
    this.dataTable.destroy();
  }
}
