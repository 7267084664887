export default class CampaignsGeolocationForm {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {    
    var self = this;
    $(".distance_range").off("input").on("input", function() {
        $("#distance_value").html(""+$(this).val());
    });

    // set distance_range range to 50 by default
    $("#distance_value").html("50");
    $(".distance_range").val(2);

    self.bindGeolocationRemoveButton();
    
    self.toggleGeolocationViews($("#campaign_use_custom_geolocations").is(":checked"));

    $("#campaign_use_custom_geolocations").on('change', function() {
      self.toggleGeolocationViews($(this).is(":checked"));

      var url = $(this).data("url");

      $.ajax({
        type: "PUT",
        url: url,
        success: function(data) {
          self.reloadGeolocations()
        }
      });
    });

    $("#add_city_btn").on("click", function() {
      event.preventDefault();
      
      var url = $(this).data("url");
      
      var name = $("#geolocation_search_city").select2("data")[0].text;
      var latitude = $("#geolocation_latitude").val();
      var longitude = $("#geolocation_longitude").val();
      var name = $("#geolocation_name").val();
      var distance = $("#distance_value").html();

      var geolocation = {
        name: name,
        latitude: latitude,
        longitude: longitude,
        name: name,
        distance: distance
      }

      $.ajax({
        type: "PUT",
        url: url,
        data: { geolocation: geolocation },
        success: function(data) {
          $("#geolocation_search_city").val(null).trigger("change");
          $("#geolocation_latitude").val("");
          $("#geolocation_longitude").val("");
          $("#geolocation_name").val("");

          $("#geolocation-list").html("");
          $("#geolocation-list").append(data);

          if ($(".geolocation-item").length == 0) {
            $("#no-geoloc").show();
            $("#geolocation-list").hide();
          } else {
            $("#no-geoloc").hide();
            $("#geolocation-list").show();
          }

          self.reloadGeolocations()
          self.bindGeolocationRemoveButton();
        }
      });
    });


    $("#geolocation_search_city").select2({
      ajax: {
        delay: 250,
        url: '/domains/search-city',
        data: function (params) {
          var query = {
            search: params.term,
          }

          // Query parameters will be ?search=[term]&type=public
          return query;
        },
        processResults: function (data) {
            self.geolocation_results = {};

            var select2Results = []

            $.each(data.results, function(idx, result) {

                self.geolocation_results[result.place_id] = result;

                select2Results.push({ text: result.display_name, id: result.place_id });
            });
          // Transforms the top-level key of the response object from 'items' to 'results'
          return {
            results: select2Results
          };
        }
      }
    }).on("select2:select", function(e) {
        var result = self.geolocation_results[$(this).val()];

        $("#geolocation_latitude").val(result.lat);
        $("#geolocation_longitude").val(result.lon);
        if(result.addresstype=="postcode") {
          $("#geolocation_name").val(result.display_name.split(",")[1]);
        }
        else {
          $("#geolocation_name").val(result.name.length>0 ? result.name : result.display_name);
        }

    });
  }

  bindGeolocationRemoveButton() {
    var self = this;

    $(".btn-destroy-geolocation").on("click", function() {            
      var url = $(this).data("url");

      $.ajax({ 
        type: "PUT",
        url: url,
        success: function(data) {
          self.reloadGeolocations()
        }
      });

      var geolocation_id = $(this).data("id");
      var row = $("#geolocation-item-"+geolocation_id);
      row.remove();

      if ($(".geolocation-item").length == 0) {
        $("#no-geoloc").show();
        $("#geolocation-list").hide();
      } else {
        $("#no-geoloc").hide();
        $("#geolocation-list").show();
      }
    });
  };

  reloadGeolocations(){
    var wrapper = $("#geolocations-wrapper");

    var url = wrapper.data("url");

    $.ajax({
      type: "GET",
      url: url,
      success: function(data) {
        wrapper.html(data);
      }
    });
  }

  toggleGeolocationViews(checked) {
    if (checked) {
      $("#geolocation-form-wrapper").hide();
      $("#geolocation-modal-dialog").removeClass("modal-xl");
      $("#domain-geocations-wrapper").show();
      $("#label-geoloc-custom").hide();
      $("#label-geoloc-domain").show();
    } else {
      $("#geolocation-form-wrapper").show();
      $("#geolocation-modal-dialog").addClass("modal-xl");
      $("#domain-geocations-wrapper").hide();
      $("#label-geoloc-custom").show();
      $("#label-geoloc-domain").hide();
    }
  }

  onPageLoad() {
    this.bindEvents();
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $(".distance_range").off("input");
    $(".btn-destroy-geolocation").off("click");
    $("#campaign_use_custom_geolocations").off("change");
    $("#geolocation_search_city").select2("destroy");
  }
}