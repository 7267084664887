export default class DomainsSettings {
    
    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        $(".frequency-select").off("change").on("change", function(e) {
            $(this).closest("form").trigger("submit");
        });
    }

}